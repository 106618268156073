import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "./logo.jpg";
import {authEndpoint, endpoint } from "./vars";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    setIdToken(token);
  }, [idToken]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const handleLogout = () => {
    localStorage.removeItem("idToken");
    setIdToken(null);
  };

  function parseJwt(token) {
    try {
      if (!token) {
        return "Not Logged in";
      }
      if (token === "null") {
        return "Not Logged in";
      }
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const email = JSON.parse(window.atob(base64)).email;
      console.log(email);
      return email;
    } catch (error) {
      console.log("Error parsing JWT", error);
      return;
    }
  }
  

  return (
    <div className="bg-gray-900 text-white py-4 px-6 flex items-center justify-between">
      <img src={logo} alt="Logo" className="w-20p h-20 flex-shrink-0" />
      <ul className="user-menu hidden md:flex md:text-lg items-center">
        <li className="mx-4">
          <Link to="/" className="text-gray-400 hover:text-white">
            Make a Booking
          </Link>
        </li>
        <li className="mx-4">
          <Link to="/bookings" className="text-gray-400 hover:text-white">
            My Bookings
          </Link>
        </li>
        {idToken ? (
          <li className="mx-4">
            <p className="ml-6">Logged in as: {parseJwt(idToken)}</p>
            <button onClick={handleLogout} className="text-gray-400 hover:text-white">
              Logout
            </button>
          </li>
        ) : (
          <li className="mx-4">
            <a
              onClick={handleLogout}
              href={`https://${authEndpoint}/login?client_id=527jee4f7418gfrfl77ed9kcsq&response_type=token&scope=email+openid&redirect_uri=https://${endpoint}/login`}
              className="text-gray-400 hover:text-white"
            >
              Login
            </a>
          </li>
        )}
        <li className="mx-4">
          <Link to="/help" className="text-gray-400 hover:text-white">
            Help
          </Link>
        </li>
      </ul>
      <div
        className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span className="md:hidden hamburger-line block w-6 h-1 bg-white mb-1"></span>
        <span className="md:hidden hamburger-line block w-6 h-1 bg-white mb-1"></span>
        <span className="md:hidden hamburger-line block w-6 h-1 bg-white"></span>
      </div>
      {isMenuOpen && (
        <ul className="user-menu md:hidden flex flex-col text-lg items-center justify-center absolute bg-gray-800 top-24 w-full py-4 px-6 duration-300">
          <li className="my-4">
            <Link onClick={toggleMenu} to="/" className="text-gray-400 hover:text-white">
              Make a Booking
            </Link>
          </li>
          <li className="my-4">
            <Link onClick={toggleMenu} to="/bookings" className="text-gray-400 hover:text-white">
              My Bookings
            </Link>
          </li>
          {idToken ? (
            <li className="my-4">
              <button onClick={handleLogout} className="text-gray-400 hover:text-white">
                Logout
              </button>
            </li>
          ) : (
                <li className="my-4">
              <a onClick={handleLogout} href="https://auth.booksmartapp.co.uk/login?client_id=527jee4f7418gfrfl77ed9kcsq&response_type=token&scope=email+openid&redirect_uri=https://booksmartapp.co.uk/login" className="text-gray-400 hover:text-white">Login</a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
  
};

export default Header;
